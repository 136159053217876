export class ImgTextAccordion {
    constructor() {
        this.init();
    }

    init() {
        const accordionWrappers = document.querySelectorAll(
            '.img-text-section__list-wrapper'
        );
        if (!accordionWrappers.length) return;

        accordionWrappers.forEach(wrapper => {
            this.initAccordion(wrapper);
        });
    }

    initAccordion(wrapper) {
        const list = wrapper.querySelector('.img-text-section__list');
        const buttons = wrapper.querySelectorAll(
            '.img-text-section__list-button'
        );
        const contents = wrapper.querySelectorAll(
            '.img-text-section__list-content'
        );
        const descriptionsContainer = wrapper.querySelector(
            '.img-text-section__list-descriptions'
        );

        if (
            !list ||
            !buttons.length ||
            !contents.length ||
            !descriptionsContainer
        )
            return;

        buttons.forEach((button, index) => {
            button.setAttribute('data-key', index);
            contents[index].setAttribute('data-key', index);
        });

        contents.forEach(content => {
            content.style.height = '0px';
        });

        this.updateReservedSpace(wrapper);

        buttons.forEach(button => {
            button.addEventListener('click', () => {
                this.toggleContent(button, contents, buttons);
            });
        });

        window.addEventListener('resize', () => {
            this.updateReservedSpace(wrapper);
            this.updateExpandedContentHeight(wrapper);
        });
    }

    updateReservedSpace(wrapper) {
        const contents = wrapper.querySelectorAll(
            '.img-text-section__list-content'
        );
        const descriptionsContainer = wrapper.querySelector(
            '.img-text-section__list-descriptions'
        );

        let maxHeight = 0;
        contents.forEach(content => {
            const contentInner = content.querySelector(
                '.img-text-section__list-content-inner'
            );
            if (contentInner) {
                const contentHeight = contentInner.offsetHeight;
                if (contentHeight > maxHeight) {
                    maxHeight = contentHeight;
                }
            }
        });

        if (descriptionsContainer && maxHeight > 0) {
            descriptionsContainer.style.minHeight = `${maxHeight}px`;
        }
    }

    toggleContent(clickedButton, allContents, allButtons) {
        const clickedIndex = clickedButton.getAttribute('data-key');
        const isExpanded = clickedButton.classList.contains('active');

        allButtons.forEach(button => {
            button.classList.remove('active');
        });

        allContents.forEach(content => {
            content.classList.remove('active');
            content.style.height = '0px';
        });

        if (!isExpanded) {
            clickedButton.classList.add('active');

            const contentToExpand = Array.from(allContents).find(
                content => content.getAttribute('data-key') === clickedIndex
            );

            if (contentToExpand) {
                contentToExpand.classList.add('active');
                const contentInner = contentToExpand.querySelector(
                    '.img-text-section__list-content-inner'
                );
                if (contentInner) {
                    contentToExpand.style.height = `${contentInner.offsetHeight}px`;
                }
            }
        }
    }

    updateExpandedContentHeight(wrapper) {
        const activeContent = wrapper.querySelector(
            '.img-text-section__list-content.active'
        );
        if (!activeContent) return;

        const contentInner = activeContent.querySelector(
            '.img-text-section__list-content-inner'
        );
        if (contentInner) {
            activeContent.style.height = `${contentInner.offsetHeight}px`;
        }
    }
}

document.addEventListener('DOMContentLoaded', () => {
    new ImgTextAccordion();
});
